exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-application-development-services-js": () => import("./../../../src/pages/services/application-development-services.js" /* webpackChunkName: "component---src-pages-services-application-development-services-js" */),
  "component---src-pages-services-big-data-analytics-services-js": () => import("./../../../src/pages/services/big-data-analytics-services.js" /* webpackChunkName: "component---src-pages-services-big-data-analytics-services-js" */),
  "component---src-pages-services-cloud-services-js": () => import("./../../../src/pages/services/cloud-services.js" /* webpackChunkName: "component---src-pages-services-cloud-services-js" */),
  "component---src-pages-services-digital-transformation-services-js": () => import("./../../../src/pages/services/digital-transformation-services.js" /* webpackChunkName: "component---src-pages-services-digital-transformation-services-js" */),
  "component---src-pages-services-enterprise-mobility-solution-js": () => import("./../../../src/pages/services/enterprise-mobility-solution.js" /* webpackChunkName: "component---src-pages-services-enterprise-mobility-solution-js" */),
  "component---src-pages-services-google-ads-management-services-js": () => import("./../../../src/pages/services/google-ads-management-services.js" /* webpackChunkName: "component---src-pages-services-google-ads-management-services-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mobile-app-development-services-js": () => import("./../../../src/pages/services/mobile-app-development-services.js" /* webpackChunkName: "component---src-pages-services-mobile-app-development-services-js" */),
  "component---src-pages-services-planning-analytics-services-js": () => import("./../../../src/pages/services/planning-analytics-services.js" /* webpackChunkName: "component---src-pages-services-planning-analytics-services-js" */),
  "component---src-pages-services-salesforce-services-js": () => import("./../../../src/pages/services/salesforce-services.js" /* webpackChunkName: "component---src-pages-services-salesforce-services-js" */),
  "component---src-pages-services-search-engine-optimization-services-js": () => import("./../../../src/pages/services/search-engine-optimization-services.js" /* webpackChunkName: "component---src-pages-services-search-engine-optimization-services-js" */),
  "component---src-pages-services-security-operations-center-services-js": () => import("./../../../src/pages/services/security-operations-center-services.js" /* webpackChunkName: "component---src-pages-services-security-operations-center-services-js" */),
  "component---src-pages-services-ui-ux-design-services-js": () => import("./../../../src/pages/services/ui-ux-design-services.js" /* webpackChunkName: "component---src-pages-services-ui-ux-design-services-js" */),
  "component---src-pages-services-vulnerability-assessment-and-penetration-testing-services-js": () => import("./../../../src/pages/services/vulnerability-assessment-and-penetration-testing-services.js" /* webpackChunkName: "component---src-pages-services-vulnerability-assessment-and-penetration-testing-services-js" */),
  "component---src-pages-services-website-design-and-development-services-js": () => import("./../../../src/pages/services/website-design-and-development-services.js" /* webpackChunkName: "component---src-pages-services-website-design-and-development-services-js" */),
  "component---src-pages-technology-stack-js": () => import("./../../../src/pages/technology-stack.js" /* webpackChunkName: "component---src-pages-technology-stack-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

